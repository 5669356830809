@import "~bootstrap/scss/bootstrap";
@import "~react-screen-orientation/src/index";

.html {
  scroll-behavior: smooth;
}

.body {
  font-family: "Open Sans", sans-serif;
}

.box {
  padding: 80px 0px 60px 0px;
}

.bg-slider {
  background-image: url("./res/slider.jpg");
  background-position: center;
}

.title,
.title-light {
  font-weight: bold;
  font-size: 18pt;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.title {
  border-bottom: 1px solid rgb(187, 187, 187);
}

.title-light {
  color: white;
  border-bottom: 1px solid rgb(212, 212, 212);
}

.btn-custom {
  color: #fff;
  background-color: #34638b;
  border-color: #2e5477;
  &:hover {
    background-color: #4782b6;
    border-color: #3b6a92;
  }
}

.bgt {
  background-color: red;
}