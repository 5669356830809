.header-space {
  padding-bottom: 80px;
}

.header {
  position: fixed;
  width: 100%;

  height: 80px;
  background: rgb(0, 159, 227);
  background: linear-gradient(90deg, rgba(0, 159, 227, 1) 0%, rgba(0, 110, 175, 1) 100%);
  padding: 0px 30px 0px 0px;

  z-index: 100;

  .logo {
  }

  .logo-name {
    font-size: 25pt;
    font-weight: bolder;
    color: white;
    white-space: nowrap;
  }

  .logo-abilities {
    position: relative;
    top: -7px;
    font-style: italic;
    color: white;
    white-space: nowrap;

  }
  .navigation {
    height: 80px;
  }

  .nav-item {
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
    margin-top: 25px;
    padding: 0 40px 0 0;
    text-align: center;
    

    a {
      color: white;
      transition: color 300ms ease;
    }

    a:hover {
      color: rgb(219, 219, 219);
      text-decoration: none;
    }
  }
}
