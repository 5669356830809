.slide-text {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  font-style: italic;
  line-height: 110%;
  margin-bottom: 8%;
  margin-top: 8%;
  margin-left: 2vw;
}

.slide-text-bg {
  background: rgb(0, 159, 227);
  background: linear-gradient(90deg, rgba(0, 159, 227, 1) 0%, rgba(0, 110, 175, 1) 100%);
  -webkit-box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.75);
  padding: 10px;

}

@media (orientation: landscape) {
  .image-landscape {
    display: block !important;
  }
  .image-portrait {
    display: none !important;
  }
  .slide-text {
    font-size: 6vw;
  }
  .carousel-slide-1-image {
    width: auto !important;
    max-height: 85vh !important;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
}

@media (orientation: portrait) { 
  .image-landscape {
    display: none !important;
  }
  .image-portrait {
    display: block !important;
  }
  .slide-text {
    font-size: 7vw;
  }
  .carousel-slide-1-image {
    width: auto !important;
    max-height: 60vh !important;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
}

/*.carousel-slide-1-image {
  width: auto !important;
  max-height: 90% !important;
  position: absolute;
  right: 0px;
  bottom: 0px;
}*/

/*
.slide-text {
  background: rgb(0, 159, 227);
  background: linear-gradient(90deg, rgba(0, 159, 227, 1) 0%, rgba(0, 110, 175, 1) 100%);
  color: white;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  font-size: 45pt;
  font-style: italic;
  line-height: 110%;
  position: absolute;
  left: 15%;
  -webkit-box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.75);
}
*/

/*
@media (orientation: landscape) {
  .carousel-slide-1-image {
    max-height: 90% !important;
  }
  .slide-text {
    color: red;
    left: 5%;
  }
  .st-1 {
    top: 20%;
  }
  .st-2 {
    top: 40%;
  }
  .st-3 {
    top: 60%;
  }
}

@media (orientation: portrait) {
  .carousel-slide-1-image {
    max-height: 90% !important;
  }
  .slide-text {
    color: blue;
    left: 50px;
  }
  .st-1 {
    top: 30%;
  }
  .st-2 {
    top: 45%;
  }
  .st-3 {
    top: 60%;
  }
}
*/
