

.family-picture {
  max-width: 100%;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.71);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.71);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.71);
  padding: 0px;
}
