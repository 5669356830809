.teaser {

  overflow: hidden;
  position: relative;

  .teaser-text-1,
  .teaser-text-2 {
    font-size: 40pt;
    font-weight: lighter;
    font-style: italic;
    line-height: 110%;
  }

  .teaser-image {
  }
}

@media (min-width: 0px) {
  .teaser {
    text-align: center;
  }
  .teaser-text-1 {
    padding-top: 30px;
  }
}

@media (min-width: 768px) {
  .teaser {
    height: 400px;
    text-align: left;

    .teaser-text-1 {
      position: absolute;
      top: 120px;
      left: 10%;
      padding-right: 280px;
      padding-top: 0;
    }

    .teaser-text-2 {
      position: absolute;
      top: 130px;
      left: 10%;
      margin-right: 250px;
    }

    .teaser-image {
      position: absolute;
      right: 0px;
      top: 20px;
    }
  }
}

@media (min-width: 991px) {
  .teaser {

    .teaser-text-1 {
      left: 2%;
    }

    .teaser-text-2 {
      left: 2%;
    }

  }
}
